import React from 'react';
import { PropTypes } from 'prop-types'
import LoginPage from 'modules/Auth/LoginPage';
import { Route } from 'react-router-dom';
import AdminLoginContainer from 'modules/Admin/Auth/components/LoginPage';

const Authenticated = ({ isAuthenticated, component, isAdmin, ...rest}) => (
	<Route {...rest} render={(props) => {
	    return isAuthenticated ?
	    (React.createElement(component, { ...props, ...rest, isAuthenticated })) :
	    ((isAdmin ? <AdminLoginContainer /> : <LoginPage />));
	  }} />
);

Authenticated.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export default Authenticated;
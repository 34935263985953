export const GET_JOBS_REQUEST = 'GET_JOBS_REQUEST';
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';
export const GET_JOBS_FAILURE = 'GET_JOBS_FAILURE';
export const SET_JOBS_ORDER = 'SET_JOBS_ORDER';
export const SET_JOBS_PAGE = 'SET_JOBS_PAGE';
export const SET_JOBS_SEARCH = 'SET_JOBS_SEARCH';

export const GET_JOB_REQUEST = 'GET_JOB_REQUEST';
export const GET_JOB_SUCCESS = 'GET_JOB_SUCCESS';
export const GET_JOB_FAILURE = 'GET_JOB_FAILURE';
export const SAVE_JOB_REQUEST = 'SAVE_JOB_REQUEST';
export const SAVE_JOB_SUCCESS = 'SAVE_JOB_SUCCESS';
export const SAVE_JOB_VALIDATION = 'SAVE_JOB_VALIDATION';
export const SAVE_JOB_FAILURE = 'SAVE_JOB_FAILURE';
export const DELETE_JOB_REQUEST = 'DELETE_JOB_REQUEST';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';
export const DELETE_JOB_FAILURE = 'DELETE_JOB_FAILURE';
import {
	GET_PAGES_REQUEST,
	GET_PAGES_SUCCESS,
	GET_PAGES_FAILURE,
	SET_PAGES_ORDER,
	SET_PAGES_PAGE,
	SET_PAGES_SEARCH,
	SAVE_PAGE_REQUEST,
	SAVE_PAGE_SUCCESS,
	SAVE_PAGE_VALIDATION,
	SAVE_PAGE_FAILURE,
	GET_PAGE_REQUEST,
	GET_PAGE_SUCCESS,
	GET_PAGE_FAILURE,
	DELETE_PAGE_REQUEST,
	DELETE_PAGE_SUCCESS,
	DELETE_PAGE_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

export function pages(state = {
	isFetching: false,
	errors: [],
	currentPage: undefined,
	saveSuccess: false,
	deleteSuccess: false,
	pages: [],
	roles: [],
	perPage: 10,
	page: 1,
	orderBy: 'title',
	orderDir: 'asc',
	search: '',
	totalPages: 1,
	status: ['active','draft']
}, action) {
	switch (action.type) {
		case GET_PAGES_REQUEST:
		case GET_PAGE_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				deleteSuccess: false
			})
		case GET_PAGES_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				pages: action.pages,
				totalPages: action.totalPages
			})
		case GET_PAGES_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_PAGES_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_PAGES_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_PAGES_SEARCH:
			return Object.assign({}, state, {
				page: 1,
				search: action.search
			})
		case SAVE_PAGE_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				saveSuccess: false
			})
		case SAVE_PAGE_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_PAGE_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true,
				currentPage: action.page
			})
		case SAVE_PAGE_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case GET_PAGE_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				currentPage: action.page
			})
		case GET_PAGE_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case DELETE_PAGE_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case DELETE_PAGE_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: true,
			})
		case DELETE_PAGE_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				pages: [],
				roles: [],
				currentPage: undefined,
				saveSuccess: false,
				deleteSuccess: false,
				page: 1,
				search: ''
			})
		default:
			return state
	}
}
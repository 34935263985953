import {
	GET_FORMS_REQUEST,
	GET_FORMS_SUCCESS,
	GET_FORMS_FAILURE,
	SET_FORMS_ORDER,
	SET_FORMS_PAGE,
	SET_FORMS_SEARCH,
	SAVE_FORM_REQUEST,
	SAVE_FORM_SUCCESS,
	SAVE_FORM_VALIDATION,
	SAVE_FORM_FAILURE,
	GET_FORM_REQUEST,
	GET_FORM_SUCCESS,
	GET_FORM_FAILURE,
	DELETE_FORM_REQUEST,
	DELETE_FORM_SUCCESS,
	DELETE_FORM_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

export function forms(state = {
	isFetching: false,
	errors: [],
	currentForm: undefined,
	saveSuccess: false,
	deleteSuccess: false,
	forms: [],
	perPage: 10,
	page: 1,
	orderBy: 'name',
	orderDir: 'asc',
	search: '',
	totalPages: 1,
	status: ['active','draft']
}, action) {
	switch (action.type) {
		case GET_FORMS_REQUEST:
		case GET_FORM_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				deleteSuccess: false
			})
		case GET_FORMS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				forms: action.forms,
				totalPages: action.totalPages
			})
		case GET_FORMS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_FORMS_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_FORMS_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_FORMS_SEARCH:
			return Object.assign({}, state, {
				page: 1,
				search: action.search
			})
		case SAVE_FORM_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				saveSuccess: false
			})
		case SAVE_FORM_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_FORM_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true,
				currentForm: action.form
			})
		case SAVE_FORM_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case GET_FORM_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				currentForm: action.form
			})
		case GET_FORM_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case DELETE_FORM_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case DELETE_FORM_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: true,
			})
		case DELETE_FORM_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				forms: [],
				currentForm: undefined,
				saveSuccess: false,
				deleteSuccess: false,
				page: 1,
				search: ''
			})
		default:
			return state
	}
}
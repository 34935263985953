import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import {
	CLEAR_ERROR,
	ADD_ERROR
} from './actionTypes'
import { auth, passwordReset, passwordResetEmail } from './modules/Admin/Auth/reducers'
import { audits } from './modules/Admin/Audit/reducers'
import { confirmations } from './modules/Admin/Confirmation/reducers'
import { events } from './modules/Admin/Event/reducers'
import { forms } from './modules/Admin/Form/reducers'
import { jobs } from './modules/Admin/Job/reducers'
import { marketingProfiles } from './modules/Admin/MarketingProfile/reducers'
import { memberships } from './modules/Admin/Membership/reducers'
import { menus } from './modules/Admin/Menu/reducers'
import { pages } from './modules/Admin/Page/reducers'
import { products } from './modules/Admin/Product/reducers'
import { settings } from './modules/Admin/Settings/reducers'
import { users } from './modules/Admin/User/reducers'

const errorMessage = (state = '', action) => {
	switch (action.type) {
		case CLEAR_ERROR:
			return ''
		case ADD_ERROR:
			return action.error;
		default:
			return state
	}
}

export default (history) => combineReducers({
	router: connectRouter(history),
	audits,
	auth,
	confirmations,
	errorMessage,
	events,
	forms,
	jobs,
	marketingProfiles,
	memberships,
	menus,
	pages,
	passwordReset,
	passwordResetEmail,
	products,
	settings,
	users
})
import {
	GET_PRODUCTS_REQUEST,
	GET_PRODUCTS_SUCCESS,
	GET_PRODUCTS_FAILURE,
	SET_PRODUCTS_ORDER,
	SET_PRODUCTS_PAGE,
	SET_PRODUCTS_SEARCH,
	SAVE_PRODUCT_REQUEST,
	SAVE_PRODUCT_SUCCESS,
	SAVE_PRODUCT_VALIDATION,
	SAVE_PRODUCT_FAILURE,
	GET_PRODUCT_REQUEST,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAILURE,
	DELETE_PRODUCT_REQUEST,
	DELETE_PRODUCT_SUCCESS,
	DELETE_PRODUCT_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

export function products(state = {
	isFetching: false,
	errors: [],
	currentProduct: undefined,
	saveSuccess: false,
	deleteSuccess: false,
	products: [],
	perPage: 10,
	page: 1,
	orderBy: 'name',
	orderDir: 'asc',
	search: '',
	totalPages: 1,
	status: ['active','draft']
}, action) {
	switch (action.type) {
		case GET_PRODUCTS_REQUEST:
		case GET_PRODUCT_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				deleteSuccess: false
			})
		case GET_PRODUCTS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				products: action.products,
				totalPages: action.totalPages
			})
		case GET_PRODUCTS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_PRODUCTS_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_PRODUCTS_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_PRODUCTS_SEARCH:
			return Object.assign({}, state, {
				page: 1,
				search: action.search
			})
		case SAVE_PRODUCT_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				saveSuccess: false
			})
		case SAVE_PRODUCT_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_PRODUCT_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true,
				currentProduct: action.product
			})
		case SAVE_PRODUCT_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case GET_PRODUCT_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				currentProduct: action.product
			})
		case GET_PRODUCT_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case DELETE_PRODUCT_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case DELETE_PRODUCT_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: true,
			})
		case DELETE_PRODUCT_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				products: [],
				currentProduct: undefined,
				saveSuccess: false,
				deleteSuccess: false,
				page: 1,
				search: ''
			})
		default:
			return state
	}
}
import axios from 'axios'
import { handleErrors } from 'actions'
import store from './index';

class API {
	constructor(dispatch, token) {
		this.dispatch = dispatch
		this.token = token
		if(token === undefined)
			this.token = localStorage.getItem('cltoken')
	}

	call(endpoint, method, newOptions, authenticated, headers) {
		let config = {
			method: method,
			url: process.env.REACT_APP_API_URL + '/api/' + endpoint,
			data: newOptions,
			// params: newOptions,
			headers: headers
		}
		if(method === 'get')
			config['params'] = newOptions
		if(authenticated){
			config['headers'] = { ...config['headers'], 'Authorization': 'Bearer ' + this.token }
		}

		return axios(config)
		.then(response => response)
		.catch(error => {
			// throw error;
			store.dispatch(handleErrors(error));
		})
	}

	login(email, password) {
		let options = {
			'email': email,
			'password': password
		}
		return this.call('login', 'post', options);
	}

	sendPasswordResetEmail(data) {
		let options = data
		return this.call('password/email', 'post', options);
	}

	resetPassword(data) {
		let options = data
		return this.call('password/reset', 'post', options);
	}

	resendVerification(email) {
		return this.call('email/resend/' + email, 'get');
	}

	getAudits(data) {
		return this.call('audits', 'get', data, true);
	}

	// Users
	getUser(id) {
		return this.call('users/' + id, 'get', {}, true);
	}

	getUsers(data) {
		return this.call('users', 'get', data, true);
	}

	getMembers(data) {
		return this.call('members', 'get', data, true);
	}

	saveUser(id, data) {
		let formData = new FormData();

	 	for(var key in data) {
	    	formData.append(key, data[key])
	    }
	    formData.append('_method', 'put')

		return this.call('users/' + id, 'post', formData, true);
	}

	createUser(data) {
		return this.call('users', 'post', data, true);
	}

	registerUser(data) {
		return this.call('users/register', 'post', data, false);
	}

	deleteUser(id) {
		return this.call('users/' + id, 'delete', {}, true);
	}

	getActiveRoles(data) {
		return this.call('roles/active', 'get', data, true);
	}

	// Media
	getMedia() {
		return this.call('media', 'get', [], true);
	}

	getMediaItem(id) {
		return this.call('media/' + id, 'get', [], true);
	}

	createMedia(data) {
		return this.call('media', 'post', data, true);
	}

	deleteMedia(id) {
		return this.call('media/' + id, 'delete', {}, true);
	}

	// Page
	getPageBySlug(slug) {
		let data = {
			'slug': slug
		}
		return this.call('pages/slug', 'get', data, false);
	}

	getPage(id) {
		return this.call('pages/' + id, 'get', {}, true);
	}

	getPages(data) {
		return this.call('pages', 'get', data, true);
	}

	savePage(id, data) {
		return this.call('pages/' + id, 'put', data, true);
	}

	createPage(data) {
		return this.call('pages', 'post', data, true);
	}

	deletePage(id) {
		return this.call('pages/' + id, 'delete', {}, true);
	}

	// Menu
	getMenu(id) {
		return this.call('menus/' + id, 'get', {}, true);
	}

	getMenus(data) {
		return this.call('menus', 'get', data, true);
	}

	saveMenu(id, data) {
		return this.call('menus/' + id, 'put', data, true);
	}

	createMenu(data) {
		return this.call('menus', 'post', data, true);
	}

	deleteMenu(id) {
		return this.call('menus/' + id, 'delete', {}, true);
	}

	// Events
	getEventBySlug(slug) {
		let data = {
			'slug': slug
		}
		return this.call('events/slug', 'get', data, false);
	}

	getActiveEvents(data) {
		return this.call('events/active', 'get', data, false);
	}

	getEvent(id) {
		return this.call('events/' + id, 'get', {}, true);
	}

	getEvents(data) {
		return this.call('events', 'get', data, true);
	}

	saveEvent(id, data) {
		return this.call('events/' + id, 'put', data, true);
	}

	createEvent(data) {
		return this.call('events', 'post', data, true);
	}

	deleteEvent(id) {
		return this.call('events/' + id, 'delete', {}, true);
	}

	// Products
	getActiveProducts(data) {
		return this.call('products/active', 'get', data, true);
	}

	getActiveProduct(id) {
		return this.call('products/active/' + id, 'get', {}, true);
	}

	getProduct(id) {
		return this.call('products/' + id, 'get', {}, true);
	}

	getProducts(data) {
		return this.call('products', 'get', data, true);
	}

	saveProduct(id, data) {
		return this.call('products/' + id, 'put', data, true);
	}

	createProduct(data) {
		return this.call('products', 'post', data, true);
	}

	deleteProduct(id) {
		return this.call('products/' + id, 'delete', {}, true);
	}

	// Orders 
	createOrder(data) {
		return this.call('orders', 'post', data, true);
	}

	// Memberships 
	activateMembership(data) {
		return this.call('memberships/activate', 'post', data, true);
	}

	getMembership(id) {
		return this.call('memberships/' + id, 'get', {}, true);
	}

	getMemberships(data) {
		return this.call('memberships', 'get', data, true);
	}

	saveMembership(id, data) {
		return this.call('memberships/' + id, 'put', data, true);
	}

	createMembership(data) {
		return this.call('memberships', 'post', data, true);
	}

	deleteMembership(id) {
		return this.call('memberships/' + id, 'delete', {}, true);
	}

	getCurrentMembershipCount() {
		return this.call('memberships/get-current-count', 'get', {}, true);
	}

	// Form
	getFormSubmission(id) {
		return this.call('forms/submissions/' + id, 'get', {}, true);
	}

	getForm(id) {
		return this.call('forms/' + id, 'get', {}, true);
	}

	getForms(data) {
		return this.call('forms', 'get', data, true);
	}

	saveForm(id, data) {
		return this.call('forms/' + id, 'put', data, true);
	}

	createForm(data) {
		return this.call('forms', 'post', data, true);
	}

	deleteForm(id) {
		return this.call('forms/' + id, 'delete', {}, true);
	}

	submitForm(id, data) {
		return this.call('forms/submit/' + id, 'post', data, false);
	}

	// Job
	// activateJobPosting(data) {
	// 	return this.call('jobs/activate', 'post', data, true);
	// }
	activateJobPosting(data) {
		let formData = new FormData();

	 	for(var key in data) {
	    	formData.append(key, data[key])
	    }
	    formData.append('_method', 'put')

		return this.call('jobs/activate', 'post', formData, true);
	}

	getActiveJobs(data) {
		return this.call('jobs/active', 'get', data, false);
	}

	getJob(id) {
		return this.call('jobs/' + id, 'get', {}, true);
	}

	getJobs(data) {
		return this.call('jobs', 'get', data, true);
	}

	saveJob(id, data) {
		// return this.call('jobs/' + id, 'put', data, true);
		let formData = new FormData();

	 	for(var key in data) {
	    	formData.append(key, data[key])
	    }
	    formData.append('_method', 'put')

		return this.call('jobs/' + id, 'post', formData, true);
	}

	createJob(data) {
		// return this.call('jobs', 'post', data, true);
		let formData = new FormData();

	 	for(var key in data) {
	    	formData.append(key, data[key])
	    }
	    formData.append('_method', 'post')

		return this.call('jobs', 'post', formData, true);
	}

	deleteJob(id) {
		return this.call('jobs/' + id, 'delete', {}, true);
	}

	// Marketing Profile
	activateMarketingProfilePosting(data) {
		return this.call('marketing-profiles/activate', 'post', data, true);
	}

	getActiveMarketingProfiles(data) {
		return this.call('marketing-profiles/active', 'get', data, false);
	}

	getMarketingProfile(id) {
		return this.call('marketing-profiles/' + id, 'get', {}, true);
	}

	getMarketingProfiles(data) {
		return this.call('marketing-profiles', 'get', data, true);
	}

	saveMarketingProfile(id, data) {
		return this.call('marketing-profiles/' + id, 'put', data, true);
	}

	createMarketingProfile(data) {
		return this.call('marketing-profiles', 'post', data, true);
	}

	deleteMarketingProfile(id) {
		return this.call('marketing-profiles/' + id, 'delete', {}, true);
	}

	// Confirmation
	getConfirmation(id) {
		return this.call('confirmations/' + id, 'get', {}, true);
	}

	getActiveConfirmation(id) {
		return this.call('confirmations/active/' + id, 'get', {}, true);
	}

	getConfirmations(data) {
		return this.call('confirmations', 'get', data, true);
	}

	saveConfirmation(id, data) {
		return this.call('confirmations/' + id, 'put', data, true);
	}

	createConfirmation(data) {
		return this.call('confirmations', 'post', data, true);
	}

	deleteConfirmation(id) {
		return this.call('confirmations/' + id, 'delete', {}, true);
	}
}

export default API;

export function call(endpoint, method, newOptions, authenticated, headers) {
	let theAPI = new API();
	return theAPI.call(endpoint, method, newOptions, authenticated, headers);
}
import {
	GET_MEMBERSHIPS_REQUEST,
	GET_MEMBERSHIPS_SUCCESS,
	GET_MEMBERSHIPS_FAILURE,
	SET_MEMBERSHIPS_ORDER,
	SET_MEMBERSHIPS_PAGE,
	SET_MEMBERSHIPS_SEARCH,
	SAVE_MEMBERSHIP_REQUEST,
	SAVE_MEMBERSHIP_SUCCESS,
	SAVE_MEMBERSHIP_VALIDATION,
	SAVE_MEMBERSHIP_FAILURE,
	GET_MEMBERSHIP_REQUEST,
	GET_MEMBERSHIP_SUCCESS,
	GET_MEMBERSHIP_FAILURE,
	DELETE_MEMBERSHIP_REQUEST,
	DELETE_MEMBERSHIP_SUCCESS,
	DELETE_MEMBERSHIP_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

export function memberships(state = {
	isFetching: false,
	errors: [],
	currentMembership: undefined,
	saveSuccess: false,
	deleteSuccess: false,
	memberships: [],
	perPage: 10,
	page: 1,
	orderBy: 'activation_code',
	orderDir: 'asc',
	search: '',
	totalPages: 1,
	status: ['active','draft']
}, action) {
	switch (action.type) {
		case GET_MEMBERSHIPS_REQUEST:
		case GET_MEMBERSHIP_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				deleteSuccess: false
			})
		case GET_MEMBERSHIPS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				memberships: action.memberships,
				totalPages: action.totalPages
			})
		case GET_MEMBERSHIPS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_MEMBERSHIPS_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_MEMBERSHIPS_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_MEMBERSHIPS_SEARCH:
			return Object.assign({}, state, {
				page: 1,
				search: action.search
			})
		case SAVE_MEMBERSHIP_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				saveSuccess: false
			})
		case SAVE_MEMBERSHIP_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_MEMBERSHIP_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true,
				currentMembership: action.membership
			})
		case SAVE_MEMBERSHIP_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case GET_MEMBERSHIP_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				currentMembership: action.membership
			})
		case GET_MEMBERSHIP_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case DELETE_MEMBERSHIP_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case DELETE_MEMBERSHIP_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: true,
			})
		case DELETE_MEMBERSHIP_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				memberships: [],
				currentMembership: undefined,
				saveSuccess: false,
				deleteSuccess: false,
				page: 1,
				search: ''
			})
		default:
			return state
	}
}
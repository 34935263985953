export const GET_PAGES_REQUEST = 'GET_PAGES_REQUEST';
export const GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS';
export const GET_PAGES_FAILURE = 'GET_PAGES_FAILURE';
export const SET_PAGES_ORDER = 'SET_PAGES_ORDER';
export const SET_PAGES_PAGE = 'SET_PAGES_PAGE';
export const SET_PAGES_SEARCH = 'SET_PAGES_SEARCH';

export const GET_PAGE_REQUEST = 'GET_PAGE_REQUEST';
export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS';
export const GET_PAGE_FAILURE = 'GET_PAGE_FAILURE';
export const SAVE_PAGE_REQUEST = 'SAVE_PAGE_REQUEST';
export const SAVE_PAGE_SUCCESS = 'SAVE_PAGE_SUCCESS';
export const SAVE_PAGE_VALIDATION = 'SAVE_PAGE_VALIDATION';
export const SAVE_PAGE_FAILURE = 'SAVE_PAGE_FAILURE';
export const DELETE_PAGE_REQUEST = 'DELETE_PAGE_REQUEST';
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_FAILURE = 'DELETE_PAGE_FAILURE';
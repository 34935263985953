export const GET_MARKETING_PROFILES_REQUEST = 'GET_MARKETING_PROFILES_REQUEST';
export const GET_MARKETING_PROFILES_SUCCESS = 'GET_MARKETING_PROFILES_SUCCESS';
export const GET_MARKETING_PROFILES_FAILURE = 'GET_MARKETING_PROFILES_FAILURE';
export const SET_MARKETING_PROFILES_ORDER = 'SET_MARKETING_PROFILES_ORDER';
export const SET_MARKETING_PROFILES_PAGE = 'SET_MARKETING_PROFILES_PAGE';
export const SET_MARKETING_PROFILES_SEARCH = 'SET_MARKETING_PROFILES_SEARCH';

export const GET_MARKETING_PROFILE_REQUEST = 'GET_MARKETING_PROFILE_REQUEST';
export const GET_MARKETING_PROFILE_SUCCESS = 'GET_MARKETING_PROFILE_SUCCESS';
export const GET_MARKETING_PROFILE_FAILURE = 'GET_MARKETING_PROFILE_FAILURE';
export const SAVE_MARKETING_PROFILE_REQUEST = 'SAVE_MARKETING_PROFILE_REQUEST';
export const SAVE_MARKETING_PROFILE_SUCCESS = 'SAVE_MARKETING_PROFILE_SUCCESS';
export const SAVE_MARKETING_PROFILE_VALIDATION = 'SAVE_MARKETING_PROFILE_VALIDATION';
export const SAVE_MARKETING_PROFILE_FAILURE = 'SAVE_MARKETING_PROFILE_FAILURE';
export const DELETE_MARKETING_PROFILE_REQUEST = 'DELETE_MARKETING_PROFILE_REQUEST';
export const DELETE_MARKETING_PROFILE_SUCCESS = 'DELETE_MARKETING_PROFILE_SUCCESS';
export const DELETE_MARKETING_PROFILE_FAILURE = 'DELETE_MARKETING_PROFILE_FAILURE';
import {
	GET_MENUS_REQUEST,
	GET_MENUS_SUCCESS,
	GET_MENUS_FAILURE,
	SET_MENUS_ORDER,
	SET_MENUS_PAGE,
	SET_MENUS_SEARCH,
	SAVE_MENU_REQUEST,
	SAVE_MENU_SUCCESS,
	SAVE_MENU_VALIDATION,
	SAVE_MENU_FAILURE,
	GET_MENU_REQUEST,
	GET_MENU_SUCCESS,
	GET_MENU_FAILURE,
	DELETE_MENU_REQUEST,
	DELETE_MENU_SUCCESS,
	DELETE_MENU_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

export function menus(state = {
	isFetching: false,
	errors: [],
	currentMenu: undefined,
	saveSuccess: false,
	deleteSuccess: false,
	menus: [],
	perPage: 10,
	page: 1,
	orderBy: 'name',
	orderDir: 'asc',
	search: '',
	totalPages: 1,
	status: ['active','draft']
}, action) {
	switch (action.type) {
		case GET_MENUS_REQUEST:
		case GET_MENU_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				deleteSuccess: false
			})
		case GET_MENUS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				menus: action.menus,
				totalPages: action.totalPages
			})
		case GET_MENUS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_MENUS_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_MENUS_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_MENUS_SEARCH:
			return Object.assign({}, state, {
				page: 1,
				search: action.search
			})
		case SAVE_MENU_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				saveSuccess: false
			})
		case SAVE_MENU_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_MENU_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true,
				currentMenu: action.menu
			})
		case SAVE_MENU_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case GET_MENU_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				currentMenu: action.menu
			})
		case GET_MENU_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case DELETE_MENU_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case DELETE_MENU_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: true,
			})
		case DELETE_MENU_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				menus: [],
				currentMenu: undefined,
				saveSuccess: false,
				deleteSuccess: false,
				page: 1,
				search: ''
			})
		default:
			return state
	}
}
export const GET_MEMBERSHIPS_REQUEST = 'GET_MEMBERSHIPS_REQUEST';
export const GET_MEMBERSHIPS_SUCCESS = 'GET_MEMBERSHIPS_SUCCESS';
export const GET_MEMBERSHIPS_FAILURE = 'GET_MEMBERSHIPS_FAILURE';
export const SET_MEMBERSHIPS_ORDER = 'SET_MEMBERSHIPS_ORDER';
export const SET_MEMBERSHIPS_PAGE = 'SET_MEMBERSHIPS_PAGE';
export const SET_MEMBERSHIPS_SEARCH = 'SET_MEMBERSHIPS_SEARCH';

export const GET_MEMBERSHIP_REQUEST = 'GET_MEMBERSHIP_REQUEST';
export const GET_MEMBERSHIP_SUCCESS = 'GET_MEMBERSHIP_SUCCESS';
export const GET_MEMBERSHIP_FAILURE = 'GET_MEMBERSHIP_FAILURE';
export const SAVE_MEMBERSHIP_REQUEST = 'SAVE_MEMBERSHIP_REQUEST';
export const SAVE_MEMBERSHIP_SUCCESS = 'SAVE_MEMBERSHIP_SUCCESS';
export const SAVE_MEMBERSHIP_VALIDATION = 'SAVE_MEMBERSHIP_VALIDATION';
export const SAVE_MEMBERSHIP_FAILURE = 'SAVE_MEMBERSHIP_FAILURE';
export const DELETE_MEMBERSHIP_REQUEST = 'DELETE_MEMBERSHIP_REQUEST';
export const DELETE_MEMBERSHIP_SUCCESS = 'DELETE_MEMBERSHIP_SUCCESS';
export const DELETE_MEMBERSHIP_FAILURE = 'DELETE_MEMBERSHIP_FAILURE';
import React, { Component } from 'react'
import LoginContainer from 'modules/Admin/Auth/containers/LoginContainer'
import 'App.scss'

class LoginPage extends Component {
	componentDidMount() {
	  document.title = 'SPMA - Login';
	}

	render() {
		return (
			<div className="login-page">
				<div className="login-page-left">
					<div className="login-page-main">
						<h1 className="login-page-title">{ this.props.title }</h1>
						<LoginContainer />
						<a href="/forgot-password" className="forgot-password-link">Forgot Password</a>
						<div className="register">
							<span>Don't have an account?</span>
							<a href="/register" className="button solid dark">Create</a>
						</div>
					</div>
				</div>
				<div className="login-page-right">
				</div>
			</div>
		)
	}
}

export default LoginPage
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import logo from 'assets/images/logo.png'
import LoginContainer from 'modules/Admin/Auth/containers/LoginContainer'
import 'Admin.scss';

class LoginPage extends Component {
	render() {
		return (
			<div className="admin login-container">
				<div className="login">
					<img src={logo} alt="SPMA" className="logo" />
					<LoginContainer />

					<nav className="login-nav menu">
						<Link to="/admin/forgot-password">Lost your password?</Link>
						<Link to="/">Back to SPMA</Link>
					</nav>
				</div>
				
			</div>
		)
	}
}

export default LoginPage;
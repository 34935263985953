export const GET_CONFIRMATIONS_REQUEST = 'GET_CONFIRMATIONS_REQUEST';
export const GET_CONFIRMATIONS_SUCCESS = 'GET_CONFIRMATIONS_SUCCESS';
export const GET_CONFIRMATIONS_FAILURE = 'GET_CONFIRMATIONS_FAILURE';
export const SET_CONFIRMATIONS_ORDER = 'SET_CONFIRMATIONS_ORDER';
export const SET_CONFIRMATIONS_PAGE = 'SET_CONFIRMATIONS_PAGE';
export const SET_CONFIRMATIONS_SEARCH = 'SET_CONFIRMATIONS_SEARCH';

export const GET_CONFIRMATION_REQUEST = 'GET_CONFIRMATION_REQUEST';
export const GET_CONFIRMATION_SUCCESS = 'GET_CONFIRMATION_SUCCESS';
export const GET_CONFIRMATION_FAILURE = 'GET_CONFIRMATION_FAILURE';
export const SAVE_CONFIRMATION_REQUEST = 'SAVE_CONFIRMATION_REQUEST';
export const SAVE_CONFIRMATION_SUCCESS = 'SAVE_CONFIRMATION_SUCCESS';
export const SAVE_CONFIRMATION_VALIDATION = 'SAVE_CONFIRMATION_VALIDATION';
export const SAVE_CONFIRMATION_FAILURE = 'SAVE_CONFIRMATION_FAILURE';
export const DELETE_CONFIRMATION_REQUEST = 'DELETE_CONFIRMATION_REQUEST';
export const DELETE_CONFIRMATION_SUCCESS = 'DELETE_CONFIRMATION_SUCCESS';
export const DELETE_CONFIRMATION_FAILURE = 'DELETE_CONFIRMATION_FAILURE';
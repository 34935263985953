import React, { Component } from 'react'
import { connect } from 'react-redux'
import Login from '../components/Login'
import { loginUser, resendVerification } from '../actions'
import { getLoginErrors, getLoginFetching, getLoginFormError } from '../selectors'
import FormWrapper from 'UI/components/FormWrapper'

const LoginForm = FormWrapper(Login, ['email', 'password'])

class LoginContainer extends Component {
	constructor(props) {
	    super(props);

		this.validate = this.validate.bind(this);
	}

	validate(name, label, value) {

		let errors = [];
		if(name === 'email' || name ==='password') {
			if(value.length === 0) {
				errors.push(label + " is required.");
			}
		}

		return errors;
	}

	render() {
		return (
			<LoginForm 
				validate={this.validate} 
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoading: getLoginFetching(state),
		apiErrors: getLoginErrors(state),
		formError: getLoginFormError(state)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onSubmit: creds => {
			// console.log(creds);
			dispatch(loginUser(creds))
		},

		onResendVerification: email => {
			dispatch(resendVerification(email))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
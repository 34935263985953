export const GET_FORMS_REQUEST = 'GET_FORMS_REQUEST';
export const GET_FORMS_SUCCESS = 'GET_FORMS_SUCCESS';
export const GET_FORMS_FAILURE = 'GET_FORMS_FAILURE';
export const SET_FORMS_ORDER = 'SET_FORMS_ORDER';
export const SET_FORMS_PAGE = 'SET_FORMS_PAGE';
export const SET_FORMS_SEARCH = 'SET_FORMS_SEARCH';

export const GET_FORM_REQUEST = 'GET_FORM_REQUEST';
export const GET_FORM_SUCCESS = 'GET_FORM_SUCCESS';
export const GET_FORM_FAILURE = 'GET_FORM_FAILURE';
export const SAVE_FORM_REQUEST = 'SAVE_FORM_REQUEST';
export const SAVE_FORM_SUCCESS = 'SAVE_FORM_SUCCESS';
export const SAVE_FORM_VALIDATION = 'SAVE_FORM_VALIDATION';
export const SAVE_FORM_FAILURE = 'SAVE_FORM_FAILURE';
export const DELETE_FORM_REQUEST = 'DELETE_FORM_REQUEST';
export const DELETE_FORM_SUCCESS = 'DELETE_FORM_SUCCESS';
export const DELETE_FORM_FAILURE = 'DELETE_FORM_FAILURE';
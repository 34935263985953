import {
	GET_JOBS_REQUEST,
	GET_JOBS_SUCCESS,
	GET_JOBS_FAILURE,
	SET_JOBS_ORDER,
	SET_JOBS_PAGE,
	SET_JOBS_SEARCH,
	SAVE_JOB_REQUEST,
	SAVE_JOB_SUCCESS,
	SAVE_JOB_VALIDATION,
	SAVE_JOB_FAILURE,
	GET_JOB_REQUEST,
	GET_JOB_SUCCESS,
	GET_JOB_FAILURE,
	DELETE_JOB_REQUEST,
	DELETE_JOB_SUCCESS,
	DELETE_JOB_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

export function jobs(state = {
	isFetching: false,
	errors: [],
	currentJob: undefined,
	saveSuccess: false,
	deleteSuccess: false,
	jobs: [],
	perPage: 10,
	page: 1,
	orderBy: 'company_name',
	orderDir: 'asc',
	search: '',
	totalPages: 1,
	status: ['active','draft']
}, action) {
	switch (action.type) {
		case GET_JOBS_REQUEST:
		case GET_JOB_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				deleteSuccess: false
			})
		case GET_JOBS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				jobs: action.jobs,
				totalPages: action.totalPages
			})
		case GET_JOBS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_JOBS_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_JOBS_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_JOBS_SEARCH:
			return Object.assign({}, state, {
				page: 1,
				search: action.search
			})
		case SAVE_JOB_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				saveSuccess: false
			})
		case SAVE_JOB_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_JOB_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true,
				currentJob: action.job
			})
		case SAVE_JOB_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case GET_JOB_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				currentJob: action.job
			})
		case GET_JOB_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case DELETE_JOB_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case DELETE_JOB_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: true,
			})
		case DELETE_JOB_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				jobs: [],
				currentJob: undefined,
				saveSuccess: false,
				deleteSuccess: false,
				page: 1,
				search: ''
			})
		default:
			return state
	}
}
import {
	GET_SETTINGS,
	GET_SETTINGS_REQUEST,
	GET_SETTINGS_FAILURE
} from './actionTypes'

export const settings = (state = {
	settings: undefined,
	isFetching: true
}, action) => {
	switch (action.type) {
		case GET_SETTINGS_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
			})
		case GET_SETTINGS:
			return Object.assign({}, state, {
				isFetching: false,
				settings: action.settings
			})
		case GET_SETTINGS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		default:
			return state
	}
}
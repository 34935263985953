import React, { Component } from 'react';
import Modal from './Modal'

class ErrorModal extends Component {
	render() {
		return (
			<Modal title="Error" onClose={this.props.onClose} className="error-modal">
				<p>{this.props.message}</p>
				<div className="buttons">
					<button onClick={this.props.onClose} className="button">Dismiss</button>
				</div>
			</Modal>
		)
	}
}

export default ErrorModal;
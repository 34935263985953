import {
	GET_EVENTS_REQUEST,
	GET_EVENTS_SUCCESS,
	GET_EVENTS_FAILURE,
	SET_EVENTS_ORDER,
	SET_EVENTS_PAGE,
	SET_EVENTS_SEARCH,
	SAVE_EVENT_REQUEST,
	SAVE_EVENT_SUCCESS,
	SAVE_EVENT_VALIDATION,
	SAVE_EVENT_FAILURE,
	GET_EVENT_REQUEST,
	GET_EVENT_SUCCESS,
	GET_EVENT_FAILURE,
	DELETE_EVENT_REQUEST,
	DELETE_EVENT_SUCCESS,
	DELETE_EVENT_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

export function events(state = {
	isFetching: false,
	errors: [],
	currentEvent: undefined,
	saveSuccess: false,
	deleteSuccess: false,
	events: [],
	perPage: 10,
	page: 1,
	orderBy: 'name',
	orderDir: 'asc',
	search: '',
	totalPages: 1,
	status: ['active','draft']
}, action) {
	switch (action.type) {
		case GET_EVENTS_REQUEST:
		case GET_EVENT_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				deleteSuccess: false
			})
		case GET_EVENTS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				events: action.events,
				totalPages: action.totalPages
			})
		case GET_EVENTS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_EVENTS_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_EVENTS_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_EVENTS_SEARCH:
			return Object.assign({}, state, {
				page: 1,
				search: action.search
			})
		case SAVE_EVENT_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				saveSuccess: false
			})
		case SAVE_EVENT_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_EVENT_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true,
				currentEvent: action.event
			})
		case SAVE_EVENT_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case GET_EVENT_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				currentEvent: action.event
			})
		case GET_EVENT_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case DELETE_EVENT_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case DELETE_EVENT_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: true,
			})
		case DELETE_EVENT_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				events: [],
				currentEvent: undefined,
				saveSuccess: false,
				deleteSuccess: false,
				page: 1,
				search: ''
			})
		default:
			return state
	}
}
import {
	GET_MARKETING_PROFILES_REQUEST,
	GET_MARKETING_PROFILES_SUCCESS,
	GET_MARKETING_PROFILES_FAILURE,
	SET_MARKETING_PROFILES_ORDER,
	SET_MARKETING_PROFILES_PAGE,
	SET_MARKETING_PROFILES_SEARCH,
	SAVE_MARKETING_PROFILE_REQUEST,
	SAVE_MARKETING_PROFILE_SUCCESS,
	SAVE_MARKETING_PROFILE_VALIDATION,
	SAVE_MARKETING_PROFILE_FAILURE,
	GET_MARKETING_PROFILE_REQUEST,
	GET_MARKETING_PROFILE_SUCCESS,
	GET_MARKETING_PROFILE_FAILURE,
	DELETE_MARKETING_PROFILE_REQUEST,
	DELETE_MARKETING_PROFILE_SUCCESS,
	DELETE_MARKETING_PROFILE_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

export function marketingProfiles(state = {
	isFetching: false,
	errors: [],
	currentMarketingProfile: undefined,
	saveSuccess: false,
	deleteSuccess: false,
	marketingProfiles: [],
	perPage: 10,
	page: 1,
	orderBy: 'name',
	orderDir: 'asc',
	search: '',
	totalPages: 1,
	status: ['active','draft']
}, action) {
	switch (action.type) {
		case GET_MARKETING_PROFILES_REQUEST:
		case GET_MARKETING_PROFILE_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				deleteSuccess: false
			})
		case GET_MARKETING_PROFILES_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				marketingProfiles: action.marketingProfiles,
				totalPages: action.totalPages
			})
		case GET_MARKETING_PROFILES_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_MARKETING_PROFILES_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_MARKETING_PROFILES_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_MARKETING_PROFILES_SEARCH:
			return Object.assign({}, state, {
				page: 1,
				search: action.search
			})
		case SAVE_MARKETING_PROFILE_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				saveSuccess: false
			})
		case SAVE_MARKETING_PROFILE_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_MARKETING_PROFILE_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true,
				currentMarketingProfile: action.marketingProfile
			})
		case SAVE_MARKETING_PROFILE_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case GET_MARKETING_PROFILE_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				currentMarketingProfile: action.marketingProfile
			})
		case GET_MARKETING_PROFILE_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case DELETE_MARKETING_PROFILE_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case DELETE_MARKETING_PROFILE_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: true,
			})
		case DELETE_MARKETING_PROFILE_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				marketingProfiles: [],
				currentMarketingProfile: undefined,
				saveSuccess: false,
				deleteSuccess: false,
				page: 1,
				search: ''
			})
		default:
			return state
	}
}
import {
	GET_CONFIRMATIONS_REQUEST,
	GET_CONFIRMATIONS_SUCCESS,
	GET_CONFIRMATIONS_FAILURE,
	SET_CONFIRMATIONS_ORDER,
	SET_CONFIRMATIONS_PAGE,
	SET_CONFIRMATIONS_SEARCH,
	SAVE_CONFIRMATION_REQUEST,
	SAVE_CONFIRMATION_SUCCESS,
	SAVE_CONFIRMATION_VALIDATION,
	SAVE_CONFIRMATION_FAILURE,
	GET_CONFIRMATION_REQUEST,
	GET_CONFIRMATION_SUCCESS,
	GET_CONFIRMATION_FAILURE,
	DELETE_CONFIRMATION_REQUEST,
	DELETE_CONFIRMATION_SUCCESS,
	DELETE_CONFIRMATION_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

export function confirmations(state = {
	isFetching: false,
	errors: [],
	currentConfirmation: undefined,
	saveSuccess: false,
	deleteSuccess: false,
	confirmations: [],
	perPage: 10,
	page: 1,
	orderBy: 'name',
	orderDir: 'asc',
	search: '',
	totalPages: 1,
	status: ['active','draft']
}, action) {
	switch (action.type) {
		case GET_CONFIRMATIONS_REQUEST:
		case GET_CONFIRMATION_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				deleteSuccess: false
			})
		case GET_CONFIRMATIONS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				confirmations: action.confirmations,
				totalPages: action.totalPages
			})
		case GET_CONFIRMATIONS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_CONFIRMATIONS_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_CONFIRMATIONS_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_CONFIRMATIONS_SEARCH:
			return Object.assign({}, state, {
				page: 1,
				search: action.search
			})
		case SAVE_CONFIRMATION_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				saveSuccess: false
			})
		case SAVE_CONFIRMATION_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_CONFIRMATION_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true,
				currentConfirmation: action.confirmation
			})
		case SAVE_CONFIRMATION_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case GET_CONFIRMATION_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				currentConfirmation: action.confirmation
			})
		case GET_CONFIRMATION_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case DELETE_CONFIRMATION_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case DELETE_CONFIRMATION_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: true,
			})
		case DELETE_CONFIRMATION_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				confirmations: [],
				currentConfirmation: undefined,
				saveSuccess: false,
				deleteSuccess: false,
				page: 1,
				search: ''
			})
		default:
			return state
	}
}
import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Authenticated from './Pages/Authenticated';
// import FrontEndContainer from './modules/FrontEndContainer';
// import DashboardContainer from './modules/Admin/Dashboard/containers/DashboardContainer';

// import LoginContainer from './modules/LoginContainer';

import ErrorModal from './UI/components/ErrorModal';

import { clearError } from './actions';
import { logoutUser } from 'modules/Admin/Auth/actions'

import { ConnectedRouter } from 'connected-react-router'
import { history } from './configureStore'

import ReactGA from 'react-ga';

const DashboardContainer = React.lazy(() => import('./modules/Admin/Dashboard/containers/DashboardContainer'));
const FrontEndContainer = React.lazy(() => import('./modules/FrontEndContainer'));

if (process.env.GOOGLE_ANALYTICS_CODE) {
  ReactGA.initialize(process.env.GOOGLE_ANALYTICS_CODE); // Production
  // ReactGA.initialize('UA-187411442-1'); // Local

  ReactGA.pageview(window.location.pathname + window.location.search);

  history.listen((location) => {
    ReactGA.pageview(location.pathname + location.search);
  });
}

class App extends Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <div className="App">
          <Suspense fallback={<div>Loading...</div>}>
            {(this.props.errorMessage ? <ErrorModal message={this.props.errorMessage} onClose={this.props.clearError} /> : '' )}
            <Switch>
                <Route exact path="/admin" render={() => <Redirect to="/login"/>}/>
                <Route exact path="/logout" 
                  render={
                    () => {
                      this.props.logout();
                      return <Redirect to="/"/>
                    }
                  } />
                {/*<Public path="/admin/login" component={AdminLoginContainer} isAuthenticated={this.props.isAuthenticated} />
                <Public path="/admin/forgot-password" component={AdminPasswordResetEmailContainer} isAuthenticated={this.props.isAuthenticated} />
                <Public path="/admin/password-reset/:token" component={AdminPasswordResetContainer} isAuthenticated={this.props.isAuthenticated} /> */}
                <Authenticated path="/admin/dashboard" component={DashboardContainer} isAuthenticated={this.props.isAuthenticated} isAdmin={true} />
                <Route component={FrontEndContainer} />
            </Switch>
          </Suspense>
        </div>
      </ConnectedRouter>
    );
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  errorMessage: PropTypes.string
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.errorMessage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearError: () => {
      dispatch(clearError())
    },

    logout: creds => {
        dispatch(logoutUser(creds))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);


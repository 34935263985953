import React, { Component } from 'react';
import Field from './Field'

class Input extends Component {
	render() {
		// const { label, errors, removeFocus, ...rest} = this.props

		return (
			<Field {...this.props}>
				<input onMouseDown={e => e.stopPropagation()} />
				
			</Field>
		)
	}
}

export default Input;